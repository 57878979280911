@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

:root {
  --color-primary: #371373;
  --color-secondary: #fef5d8;
  --color-tertiary: #e6d2f7;
  --color-quartenary: #f8f8f8;
  --bs-nav-link-font-size: 18px;
  --bs-nav-link-color: black;
  --heading-color: var(--color-primary);
}

@font-face {
  font-family: "Agrandir";
  src: local("Agrandir"), url(./fonts/Agrandir-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Agrandir";
  font-weight: 700;
  src: local("Agrandir"), url(./fonts/Agrandir-TextBold.otf) format("opentype");
}

/* RESET */

body {
  font-family: "Poppins", sans-serif;
  line-height: 26px;
}

h1,
h2,
h3,
h4 {
  font-family: "Agrandir", sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  line-height: 28px;
}

.bg-1 {
  padding: 30px;
  color: var(--color-tertiary);
  background-color: var(--color-primary);
  border-radius: 8px;
}

.bg-2 {
  padding: 30px;
  background-color: var(--color-secondary);
  border-radius: 8px;
}

.bg-3 {
  padding: 30px;
  background-color: var(--color-tertiary);
  border-radius: 8px;
}

.bg-4 {
  padding: 30px;
  background-color: var(--color-quartenary);
  border-radius: 8px;
}

@media (min-width: 992px) {
  .bg-1,
  .bg-2,
  .bg-3,
  .bg-4 {
    padding: 80px;
  }
}

.mb-70 {
  margin-bottom: 70px;
}

.banner-title {
  font-family: "Agrandir", sans-serif;
  color: var(--color-primary);
  font-size: 48px;
  font-weight: 700;
}

.banner-subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
}

.quote-card {
  background-color: var(--color-quartenary);
  border-radius: 10px;
  padding: 30px;
}

.quote-card h3 {
  font-family: "Agrandir", sans-serif;
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
}

.quote-card blockquote {
  padding-top: 80px;
  margin: 0;
}

.logo {
  width: 100%;
  max-width: 200px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.navbar-component {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1030;
}

.navbar-component .navbar-toggler:focus {
  box-shadow: none;
}

@media (min-width: 992px) {
  .navbar-component {
    background-color: transparent;
  }
}

header {
  height: 100px;
}

.content {
  padding-top: 100px;
}

.heading {
  position: relative;
  color: var(--heading-color);
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  margin-bottom: 24px;
}

.heading-secondary {
  color: var(--heading-color);
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
}

.heading-label {
  display: inline-flex;
  font-weight: 500;
  font-size: 12px;
  padding: 2px 10px;
  text-transform: uppercase;
  color: white;
  background-color: var(--color-primary);
  border-radius: 20px;
  max-width: fit-content;
}

.bg-1 .heading-label {
  color: var(--color-primary);
  background-color: var(--color-tertiary);
}

@media (max-width: 992px) {
  .heading-secondary {
    font-size: 22px;
    margin-top: 20px;
  }
}

/* BUTTONS */

.button {
  text-align: center;
  display: inline-block;
  font-family: "Poppins";
  font-weight: 500;
  padding: 15px 20px;
  border-radius: 6px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: 0.3s;
  cursor: pointer;
}

.button-primary {
  color: white !important;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.button-primary:hover {
  color: black !important;
  background-color: var(--color-tertiary);
  border: 1px solid var(--color-tertiary);
}

.button-secondary {
  color: var(--color-primary);
  background-color: white;
  border: white;
}

.button-secondary:hover {
  background-color: var(--color-primary);
}

/* FOOTER */
.footer {
  background-color: var(--color-tertiary);
  color: var(--color-primary);
  padding: 30px;
  margin-bottom: 70px;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .quote-card,
  .footer {
    padding: 80px;
  }
}

.footer-mail-link {
  word-break: break-all;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-copywright {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-weight: 300;
  font-size: 14px;
  margin-top: 70px;
}

.footer-copywright p {
  margin-bottom: 0;
}

.footer-icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footer-icons .fa-brands {
  font-size: 24px;
}

.footer .heading {
  margin-bottom: 40px;
}

.highlighted {
  font-weight: 600;
}

.icon-wrapper {
  display: flex;
}

.icon-wrapper img {
  max-width: 100%;
}

.checked {
  color: #fecf02;
}

/* BOOKS */

.book-favorite {
  display: flex;
  gap: 40px;
}

.book-favorite img {
  max-width: 400px;
  flex: 2;
}

@media (max-width: 992px) {
  .book-favorite {
    flex-direction: column;
    align-items: center;
  }
}

.book-suggestion {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 60px;
  box-shadow: 0px 34px 64px 0px rgba(55, 19, 115, 0.3);
}

.book-suggestion h4 {
  font-weight: 300;
  font-size: 18px;
}

@media (max-width: 992px) {
  .book-suggestion h3 {
    text-align: center;
  }
}

.card-buy {
  background-color: #fff7e8;
  max-width: 800px;
  height: 600px;
  border-radius: 79% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;
  margin: 0 auto;
}

.card-text {
  min-height: 78px;
}

.card-body {
  padding: 40px;
}

.card-img-1 {
  background: linear-gradient(180deg, rgb(83 42 107) 0%, rgb(255 255 255) 20%);
}

.card-img-2 {
  background: linear-gradient(
    180deg,
    rgb(247 143 155) 0%,
    rgb(255 255 255) 20%
  );
}

@media (max-width: 992px) {
  .card {
    margin-bottom: 50px;
  }

  .card .button {
    width: 100%;
  }
}

.pt-sm-150 {
  padding-top: 150px;
}

.blog-link {
  display: flex;
  justify-content: space-between;
  color: black;
  border-radius: 10px;
  background-color: #e6d2f7;
  padding: 20px;
  transition: all 0.15s ease-in-out;
}

.blog-link:hover {
  transform: translate(-0.25rem, -0.25rem);
  box-shadow: 0.25rem 0.25rem 0rem #ff90e7;
}

.blog-link p {
  margin: 0;
}

.fa-brands {
  color: var(--color-primary);
}
